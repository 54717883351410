import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../Components/Loader";
import Schedule_Medical_Consultations_At_Drdaleel from "../Pages/SingleBlog/schedule-medical-consultations-at-drdaleel";
import Your_Trusted_Online_Doctor_Booking_App from "../Pages/SingleBlog/your-trusted_online_doctor_booking_app";
import TermsCondition from "../Pages/TermsCondition";
import RefundCancellationPolicy from "../Pages/RefundCancellationPolicy";
import BookTest from "../Pages/BookTest";
import SingleBookTest from "../Pages/SingleBookTest";
import ShipDelivery from "../Pages/ShipDelivery";
// import Technological_advancements_have_transformed_various_aspects_of_our_lives from "../Pages/SingleBlog/technological_advancements_have_transformed_various_aspects_of_our_lives";
// import PrivacyPolicy from "../Pages/PrivacyPolicy";
// import DoctorLogin from "../Components/DoctorLogin";
// import DoctorSignup from "../Components/DoctorSignup";
// import Login from "../Components/LoginPage";
// import SignUp from "../Components/SignupPage";
// import VerificationModal from "../Components/VerificationModel";
// import AboutPage from "../Pages/About";
// import Blogs from "../Pages/Blogs";
// import ContactUs from "../Pages/ContactUs";
// import HowItWork from "../Pages/HowItWork";
// import LandingPages from "../Pages/LandingPage";

const LandingPages = lazy(() => import("../Pages/LandingPage"));
const DoctorLogin = lazy(() => import("../Components/DoctorLogin"));
const DoctorSignup = lazy(() => import("../Components/DoctorSignup"));
const Login = lazy(() => import("../Components/LoginPage"));
const SignUp = lazy(() => import("../Components/SignupPage"));
const VerificationModal = lazy(() => import("../Components/VerificationModel"));
const AboutPage = lazy(() => import("../Pages/About"));
const Blogs = lazy(() => import("../Pages/Blogs"));
const ContactUs = lazy(() => import("../Pages/ContactUs"));
const HowItWork = lazy(() => import("../Pages/HowItWork"));
const PrivacyPolicy = lazy(() => import("../Pages/PrivacyPolicy"));
const Dr_Daleel_your_comprehensive_solution_for_online_doctor_consultations =
  lazy(() =>
    import(
      "../Pages/SingleBlog/dr_daleel_your_comprehensive_solution_for_online_doctor_consultations"
    )
  );
const Finding_the_right_doctor_and_scheduling_appointments = lazy(() =>
  import(
    "../Pages/SingleBlog/finding_the_right_doctor_and_scheduling_appointments"
  )
);
const Technological_advancements_have_transformed_various_aspects_of_our_lives =
  lazy(() =>
    import(
      "../Pages/SingleBlog/technological_advancements_have_transformed_various_aspects_of_our_lives"
    )
  );
const Sitemap = lazy(() => import("../Pages/Sitemap/index.js"));

function AllRoute() {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<LandingPages />} />
          <Route path="/book-test" element={<BookTest />} />
          <Route
            path="/single-book-test/:testId"
            element={<SingleBookTest />}
          />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/how-it-work" element={<HowItWork />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/doctor-signup" element={<DoctorSignup />} />
          <Route path="/doctor-login" element={<DoctorLogin />} />
          <Route path="/verificationmodel" element={<VerificationModal />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/ship-delivery" element={<ShipDelivery />} />
          <Route
            path="/refund-cancellation-policy"
            element={<RefundCancellationPolicy />}
          />
          <Route
            path="/dr-daleel-your-comprehensive-solution-for-online-doctor-consultations"
            element={
              <Dr_Daleel_your_comprehensive_solution_for_online_doctor_consultations />
            }
          />
          <Route
            path="/finding-the-right-doctor-and-scheduling-appointments"
            element={<Finding_the_right_doctor_and_scheduling_appointments />}
          />
          <Route
            path="/technological-advancements-have-transformed-various-aspects-of-our-lives"
            element={
              <Technological_advancements_have_transformed_various_aspects_of_our_lives />
            }
          />
          <Route
            path="/your-trusted-online-doctor-booking-app"
            element={<Your_Trusted_Online_Doctor_Booking_App />}
          />
          <Route
            path="/schedule-medical-consultations-at-drdaleel"
            element={<Schedule_Medical_Consultations_At_Drdaleel />}
          />
          <Route path="/sitemap.xml" element={<Sitemap />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default AllRoute;
