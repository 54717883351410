import axios from "axios";

export const serverUrl = "https://drdaleel.com/api";
export const imgUrl = "https://drdaleelapp.s3.us-east-2.amazonaws.com/";

export const getApihandler = async (endPoint) => {
  try {
    const getres = await axios.get(serverUrl + endPoint);
    return getres.data;
  } catch (error) {
    return { message: error.message, status: 400 };
  }
};

export const getbyidApihandler = async (endPoint) => {
  try {
    const getres = await axios.get(serverUrl + endPoint);
    // console.log("getresbyid=>", getres);
    return getres.data;
  } catch (error) {
    return { message: error.message, status: 400 };
  }
};

export const postApihandler = async (endPoint, value) => {
  try {
    const postRes = await axios.post(serverUrl + endPoint, value);
    console.log("apipost=>", postRes);
    return postRes.data;
  } catch (error) {
    return { message: error.response.data, status: 400 };
  }
};
export const putApihandler = async (endPoint, value) => {
  try {
    const res = await axios.put(serverUrl + endPoint, value);
    return res.data;
  } catch (error) {
    return { message: error.message, status: 400 };
  }
};
