import React from "react";
import SingleBlog from "../../Components/SingleBlog";
import cardimg4 from "../../Images/Blogs/cardimg4.jpg";
const blogDetails = {
  imageUrl: cardimg4,
  blogTitle: "DrDaleel: Your Trusted Online Doctor Booking App",
};

const BlogContent = [
  {
    title: "Introduction",
    para: "In the fast-paced world we live in, convenience and accessibility have become paramount. And when it comes to our health, finding the right doctor and scheduling appointments shouldn't be a hassle. Enter DrDaleel, the innovative online doctor booking app that connects you with trusted healthcare professionals in just a few taps.",
  },
  {
    title: "Seamless Appointment Booking",
    para: "With DrDaleel, gone are the days of endlessly searching for available doctors and making countless phone calls. The app provides a seamless experience, allowing users to book appointments with ease. Simply search for doctors based on their specialty, location, or availability, and book a suitable time slot that works for you.",
  },
  {
    title: "Extensive Database of Doctors",
    para: "DrDaleel boasts an extensive database of qualified and trusted doctors across various medical specialties. From general practitioners to specialized surgeons, the app ensures that you have access to a wide range of healthcare professionals. Each doctor profile includes detailed information, qualifications, and patient reviews to help you make an informed choice.",
  },
  {
    title: "Real-Time Availability and Reminders",
    para: "Never miss an appointment again with DrDaleel's real-time availability feature. The app provides up-to-date information on doctors' schedules, including any cancellations or rescheduling. Additionally, it sends you timely reminders and notifications, ensuring that you stay on top of your healthcare routine.",
  },
  {
    title: "Telemedicine and Online Consultations",
    para: "Recognizing the importance of convenience and accessibility, DrDaleel offers telemedicine and online consultation services. Through secure video calls, voice calls, or chat, you can consult with doctors from the comfort of your own home. This feature is particularly beneficial for non-emergency situations, saving you time and travel expenses.",
  },
  {
    title6: "Patient Reviews and Ratings",
    para6:
      "Making the right choice for your health is crucial, and DrDaleel understands that. The app provides patient reviews and ratings for each doctor, allowing you to gauge the quality and credibility of healthcare professionals. By hearing about the experiences of others, you can feel more confident in your decision-making process.",
  },
  {
    title6: "Secure and Convenient Payment",
    para6:
      "DrDaleel ensures a secure and convenient payment process. Integrated payment gateways allow you to make online payments for appointments or online consultations with ease. Whether it's through credit cards, debit cards, or digital wallets, your transactions are handled securely and transparently.",
  },
  {
    title6: "Personalized Experience",
    para6:
      "The app aims to provide a personalized experience tailored to your needs. By understanding your preferences and medical history, DrDaleel offers recommendations for doctors, specialists, or health-related content that align with your interests. This level of personalization enhances your overall user experience and ensures that your healthcare journey is seamless.",
  },
  {
    title: "Conclusion",
    para: "DrDaleel is transforming the way we connect with healthcare professionals and manage our medical appointments. Its user-friendly interface, extensive doctor database, real-time availability, and convenient features make it a go-to solution for booking appointments and seeking healthcare advice. With DrDaleel in your pocket, you can take control of your health and well-being with confidence and convenience. Download the app today and experience the future of online doctor booking!",
  },
];
export default function Your_Trusted_Online_Doctor_Booking_App() {
  return (
    <div>
      <SingleBlog
        imageUrl={blogDetails.imageUrl}
        blogTitle={blogDetails.blogTitle}
        // publishDate={blogDetails.publishDate}
        // author={blogDetails.author}
        BlogContent={BlogContent}
      />
    </div>
  );
}
