import React from "react";
import Layout from "../../Layout/layout";
import "./singleBlog.css";

import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import RecommendedArticle from "../Recommended/recommended";

const SingleBlog = ({ imageUrl, blogTitle, BlogContent }) => {
  return (
    <>
      <Layout>
        <div className="Single-Blog-Wrapper py-3 text-md-start">
          <Container maxWidth="lg">
            <Grid container>
              <Grid xs={12} data-aos="zoom-in">
                <div className="image-wrapper my-5">
                  <img
                    src={imageUrl}
                    alt={blogTitle}
                    width="100%"
                    height="50vh"
                  />
                </div>
                <div className="blogTitle-Wrapper pb-4">
                  <h1 className="text-md-start">{blogTitle}</h1>
                </div>
                {BlogContent.map((val) => {
                  return (
                    <div className="blogContent-Wrapper my-2">
                      <h2 className="blogContent-title">{val.title}</h2>
                      <p className="blogContent-Para">{val.para}</p>
                    </div>
                  );
                })}
              </Grid>
            </Grid>
          </Container>
        </div>
        <RecommendedArticle/>
      </Layout>
    </>
  );
};

export default SingleBlog;
