import { Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../Layout/layout";
import styles from "./terms.module.css";

export default function RefundCancellationPolicy() {
  return (
    <Layout>
      <div className={styles.outer_div}>
        <div className={styles.heading}>
          <h3>Refund and Cancellation Policy</h3>
          <p>
            At DrDaleel, we are committed to providing you with the best
            possible experience when using our website and mobile app for
            accessing healthcare services. To ensure transparency and clarity
            regarding our refund and cancellation policy, we have outlined the
            following terms and conditions:
          </p>

          <h5>1. Cancellation Policy:</h5>
          <h5>1.1. Appointments by Users:</h5>
          <p>
            Users can cancel their booked appointments with healthcare providers
            up to 24 hours before the scheduled appointment time without
            incurring any charges. Cancellations made within 24 hours of the
            appointment time may be subject to cancellation fees, which will be
            determined by the healthcare provider's policy.
          </p>

          <h5>1.2. Healthcare Provider Cancellations:</h5>
          <p>
            In the event that a healthcare provider needs to cancel a confirmed
            appointment due to unforeseen circumstances, users will be promptly
            notified, and every effort will be made to reschedule the
            appointment at a convenient time.
          </p>
          <h5>2. Refund Policy:</h5>
          <h5>2.1. Refunds for Cancelled Appointments:</h5>
          <p>
            If a user cancels an appointment within the specified timeframe (as
            mentioned in section 1.1), any prepayment made for the appointment
            will be refunded in full.
          </p>
          <h5>2.2. Refunds for Unfulfilled Services:</h5>
          <p>
            In the unlikely event that a healthcare provider is unable to
            fulfill a scheduled appointment, DrDaleel will facilitate a full
            refund to the user.
          </p>
          <h5>2.3. Billing Disputes:</h5>
          <p>
            If a user believes there is an error in billing or has concerns
            about a charge related to our services, they are encouraged to
            contact our customer support team promptly. We will investigate the
            matter and, if necessary, issue refunds for valid disputes.
          </p>
          <h5>3. Refund Process:</h5>
          <p>
            Refunds will be processed using the same payment method that was
            used for the original transaction. It may take up to [number of
            days] business days for the refund to be reflected in the user's
            account, depending on the user's bank or payment provider.
          </p>
          <h5>4. Contact Information:</h5>
          <p>
            For any inquiries, cancellations, or refund requests, users can
            contact our customer support team through the DrDaleel website or
            app. Our support team is available [hours of operation].
          </p>
          <h5>5. Policy Updates:</h5>
          <p>
            DrDaleel reserves the right to update or modify this Refund and
            Cancellation Policy at any time. Users will be notified of any
            changes through our website or app. By using the DrDaleel platform,
            you agree to abide by this Refund and Cancellation Policy. We
            encourage users to read and understand these terms before scheduling
            appointments or using our services.
          </p>
          <p>Last updated: 20/09/2023</p>
          <p>
            If you have any questions or require further clarification regarding
            our refund and cancellation policy, please do not hesitate to
            contact our customer support team. Your satisfaction is important to
            us, and we are here to assist you in any way we can.
          </p>
        </div>
      </div>
    </Layout>
  );
}
