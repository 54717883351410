import { Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../Layout/layout";
import styles from "./terms.module.css";

export default function TermsCondition() {
  return (
    <Layout>
      <div className={styles.outer_div}>
        <div className={styles.heading}>
          <h3>Terms and Conditions for drdaleel</h3>
          <h5>Acceptance of Terms:</h5>
          <p>
            By accessing or using the DrDaleel website, you agree to comply with
            and be bound by these terms and conditions of use. If you do not
            agree with any part of these terms, please refrain from using the
            website.
          </p>

          <h5>Use of the Website:</h5>
          <p>
            DrDaleel is intended for informational purposes related to health
            and medical topics. It is not a substitute for professional medical
            advice.
          </p>
          <p>
            You must be at least 18 years old or have parental/guardian consent
            to use this website.
          </p>
          <p>
            You are responsible for ensuring that your use of the website
            complies with all applicable laws and regulations.
          </p>
          <h5>Medical Disclaimer:</h5>
          <p>
            The content on DrDaleel is provided for informational purposes only
            and is not a substitute for professional medical advice, diagnosis,
            or treatment.
          </p>
          <p>
            Always seek the advice of your physician or other qualified health
            provider with any questions you may have regarding a medical
            condition.
          </p>

          <h5>User Content:</h5>
          <p>
            Users may contribute content (such as reviews, comments, or
            questions) to DrDaleel. By doing so, you grant DrDaleel the right to
            use, modify, display, and distribute your content.
          </p>
          <p>
            User-generated content must adhere to our guidelines and should not
            be false, misleading, or harmful.
          </p>
          <h5>Privacy Policy:</h5>
          <p>
            Our Privacy Policy outlines how we collect, use, and protect your
            personal information. By using DrDaleel, you agree to our Privacy
            Policy.
          </p>

          <h5>Intellectual Property:</h5>
          <p>
            All content on DrDaleel, including text, graphics, logos, and
            images, is protected by copyright and other intellectual property
            rights owned by DrDaleel or its licensors.
          </p>

          <h5>Third-Party Links:</h5>
          <p>
            DrDaleel may contain links to third-party websites. We are not
            responsible for the content or practices of these third-party sites
            and recommend reviewing their terms and privacy policies.
            <br />
          </p>

          <h5>Limitation of Liability:</h5>
          <p>
            DrDaleel and its affiliates are not liable for any direct, indirect,
            incidental, consequential, or punitive damages resulting from your
            use of the website or its content.
          </p>
          <h5>Changes to Terms and Conditions:</h5>
          <p>
            DrDaleel reserves the right to modify these terms and conditions at
            any time. It is your responsibility to review them periodically.
          </p>
          <h5>Governing Law:</h5>
          <p>
            These terms and conditions are governed by and construed in
            accordance with the laws of [Your Jurisdiction], without regard to
            its conflict of law principles.
          </p>
          <h5>Contact Information:</h5>
          <p>
            If you have any questions or concerns about these terms and
            conditions, please contact us at [Contact Email].
          </p>
          <p>
            By using DrDaleel, you acknowledge that you have read, understood,
            and agreed to these terms and conditions.
          </p>
        </div>
      </div>
    </Layout>
  );
}
