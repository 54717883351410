import React from "react";
import SingleBlog from "../../Components/SingleBlog";
import cardimg5 from "../../Images/Blogs/cardimg5.jpg";
const blogDetails = {
  imageUrl: cardimg5,
  blogTitle:
    "DrDaleel: Technological advancements have transformed various aspects of our lives",
};

const BlogContent = [
  {
    title: "Introduction",
    para: "In today's busy world, finding the time to schedule medical consultations can often feel like a daunting task. But what if there was an innovative solution that could make this process effortless and convenient? Introducing DrDaleel, the platform that revolutionizes the way you schedule and manage medical consultations.",
  },
  {
    title: "Convenience at Your Fingertips",
    para: "DrDaleel is your one-stop destination for scheduling medical consultations with ease. Whether you need to see a specialist or a general practitioner, the platform brings the convenience of booking appointments right to your fingertips. No more waiting on hold or playing phone tag; with DrDaleel, you can schedule your consultations anytime, anywhere.",
  },
  {
    title: "Extensive Network of Healthcare Professionals",
    para: "DrDaleel boasts an extensive network of trusted healthcare professionals across various specialties. From cardiologists to dermatologists, you can find a wide range of qualified doctors available for consultations. Each healthcare professional's profile includes detailed information about their qualifications, areas of expertise, and patient reviews, helping you make an informed decision.",
  },
  {
    title: "User-Friendly Appointment Booking",
    para: "Using DrDaleel is a breeze, thanks to its user-friendly appointment booking system. Simply create an account, browse through the available doctors, and select a suitable appointment slot based on your preferred date, time, and location. The platform provides real-time availability, ensuring that you have access to up-to-date appointment options.",
  },
  {
    title: "Seamless Communication",
    para: "DrDaleel facilitates seamless communication between patients and healthcare professionals. Once your appointment is confirmed, you can communicate with your doctor through the platform's secure messaging system. This allows you to discuss your medical concerns, share relevant information, and ask questions—all without the need for separate phone calls or emails.",
  },
  {
    title6: "Reminders and Notifications",
    para6:
      "With DrDaleel, you can say goodbye to missed appointments. The platform sends timely reminders and notifications, ensuring that you never forget a consultation. These reminders help you stay on top of your healthcare routine and ensure that you receive the care you need when you need it.",
  },
  {
    title6: "Telemedicine and Online Consultations",
    para6:
      "In addition to in-person consultations, DrDaleel also offers telemedicine and online consultation services. This means you can have medical consultations from the comfort of your own home, saving you time and eliminating the need for travel. Telemedicine consultations are especially valuable for non-emergency situations or follow-up appointments.",
  },
  {
    title6: "Personalized Experience",
    para6:
      "DrDaleel aims to provide a personalized experience tailored to your healthcare needs. The platform takes into account your preferences, medical history, and location to offer personalized recommendations for doctors and healthcare content that aligns with your interests.",
  },
  {
    title: "Conclusion",
    para: "DrDaleel is transforming the way we schedule medical consultations, making it easier than ever to access the care we need. With its user-friendly interface, extensive network of healthcare professionals, and convenient features like telemedicine consultations, DrDaleel simplifies your healthcare journey. Say goodbye to long wait times and frustrating appointment scheduling processes. Embrace the future of healthcare with DrDaleel—where scheduling medical consultations is a breeze. Download the app today and take control of your healthcare experience.",
  },
  {
    title: "Disclaimer",
    para: "DrDaleel does not provide medical advice. Consult a healthcare professional for personalized medical advice and treatment.",
  },
];
export default function Schedule_Medical_Consultations_At_Drdaleel() {
  return (
    <div>
      <SingleBlog
        imageUrl={blogDetails.imageUrl}
        blogTitle={blogDetails.blogTitle}
        // publishDate={blogDetails.publishDate}
        // author={blogDetails.author}
        BlogContent={BlogContent}
      />
    </div>
  );
}
