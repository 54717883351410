import "./App.css";
// import { BrowserRouter } from "react-router-dom";
import { HashRouter, BrowserRouter } from "react-router-dom";
import AllRoute from "./Routes/route";
function App() {
  return (
    <div className="App">
      <HashRouter>
        <AllRoute />
      </HashRouter>
    </div>
  );
}

export default App;
