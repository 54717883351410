import React from 'react'
import Footer from './Footer/footer'
import Navbar from './Navbar/navbar'

export default function Layout({children}) {
  return (
   <>
    <Navbar/>
      {children}
    <Footer/>
    </>
  )
}
