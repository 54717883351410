import { Grid } from "@mui/material";
import { Container, style } from "@mui/system";
import React from "react";
import styles from "./about.module.css";
import TestIcon from "../../Images/BookTest/x-rays.png";
import "../../Pages/BookTest/index.css";
import { getApihandler } from "../../ApiHandler";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function TestList() {
  const [laboratoryData, setLaboratoryData] = useState([]);
  console.log("laboratoryData", laboratoryData);

  useEffect(() => {
    getLaboratory();
  }, []);

  // API's Function

  const getLaboratory = async () => {
    let result = await getApihandler("/getLaboratoryTest/All");
    console.log("res-", result);
    setLaboratoryData(result.data);
  };
  return (
    <Container maxWidth="xl" className={styles.LightBGSections}>
      <Grid container className="px-5">
        {laboratoryData !== undefined && laboratoryData !== null ? (
          laboratoryData.map((val, ind) => {
            return (
              <Grid md={3} sm={4} xs={10} data-aos="slide-down">
                <div className="test-box-wrapper py-4 me-4 mb-4 py-4">
                  <div className="test-img-wrapper mb-4">
                    <img
                      className={styles.aboutcard_img}
                      src={TestIcon}
                      alt="Our feature"
                    />
                  </div>
                  <div className="aboutcard_outerdiv_text">
                    <h4 className="bookText_headingtext">{val.testName}</h4>
                    <p className="aboutcontaint_paragraphtext text-center">
                      ₹{val.price}
                    </p>
                    <h6 className="bookText_btn">
                      <Link
                        to={`/single-book-test/${val._id}`}
                        style={{ textDecoration: "none" }}
                      >
                        Book Now &gt;
                      </Link>
                    </h6>
                  </div>
                </div>
              </Grid>
            );
          })
        ) : (
          <Grid xs={12} data-aos="slide-down">
            <div className="noData-wrapper">
              <h4 className="bookText_headingtext text-center w-100">
                No Data Found
              </h4>
            </div>
          </Grid>
        )}

        <Grid
          md={3}
          sm={4}
          xs={10}
          className={styles.aboutcard_text_outergrid}
          data-aos="slide-up"
        ></Grid>
      </Grid>
    </Container>
  );
}
