import { Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import navlogo from "../../Images/Navbar/navlogo.png";
import styles from "./footer.module.css";
import React from "react";
import { Link } from "react-router-dom";
const Tabs = [
  { name: "Home", url: "/" },
  { name: "About", url: "/about" },
  { name: "How It Works", url: "/how-it-work" },
  { name: "Blogs", url: "/blogs" },
  { name: "Contact Us", url: "/contact-us" },
  { name: "Privacy Policy", url: "/privacy-policy" },
  { name: "Terms and Conditions", url: "/terms-condition" },
  {
    name: "Refund and Cancellation Policy",
    url: "/refund-cancellation-policy",
  },
];
const Icon = [
  {
    icon: (
      <a
        href="https://www.facebook.com/people/Drdaleel-Platform/pfbid0vH9Ef3nShukX2d3v7A4N8nTQ3BrLaz1S6z7eCFmNyH9fhFHD822QJZWRcYZdsjV8l/"
        target="_blank"
      >
        <FacebookIcon color="primary" sx={{ fontSize: "2.5rem" }} />
      </a>
    ),
  },
  {
    icon: (
      <a href="https://www.instagram.com/drdaleelofficial/" target="_blank">
        <InstagramIcon color="primary" sx={{ fontSize: "2.5rem" }} />
      </a>
    ),
  },
  { icon: <TwitterIcon color="primary" sx={{ fontSize: "2.5rem" }} /> },
];
export default function Footer() {
  return (
    <Container className={styles.footer_outer_container} maxWidth="xl">
      <Grid container className={styles.footer_cities_details}>
        <Grid xs={12}>
          <Typography className={styles.title}>
            The Dr. Daleel app is suitable for those who are in a hurry and need
            a doctor immediately. Our specialists are quick to respond and would
            quickly resolve your troubles. At present, we provide Doctors in the
            following regions:
          </Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Guwahati</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Dibrugarh</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Silchar</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Tinsukia</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Dhemaji</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Lakhimpur</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>
            Biswanath Chariali
          </Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Tezpur</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Jorhat</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Sivasagar</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Moran</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Duliajan</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Golaghat</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Nagaon</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Diphu</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Karimganj</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Bongaigaon</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Nalbari</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Kokrajar</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Udalguri</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Sonari</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Majuli</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Itanagar</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Pasighat</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Tawang</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Seppa</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Along</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Zero</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Naharlagun</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Imphal</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Dimapur</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Kohima</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Shillong</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Agartala</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography className={styles.cityName}>Aizawl</Typography>
        </Grid>
      </Grid>
      <hr className={styles.hrz_line} />
      <Grid container className={styles.footer_gridcontainer_div}>
        <Grid xs={12} sm={3}>
          <div>
            <Link to="/">
              <img
                className={styles.footer_img}
                src={navlogo}
                alt="Drdaleel logo"
                width="100%"
                height="100%"
              />
            </Link>
          </div>
          <Typography sx={{ color: "#1855BB", fontWeight: "600" }}>
            DR Daleel
          </Typography>
        </Grid>
        <Grid xs={12} sm={3}>
          <Typography className={styles.footer_upper_tabtext} variant="h6">
            COMPANY
          </Typography>
          <div>
            {Tabs.map((tab) => {
              return (
                <div style={{ paddingTop: "10px" }}>
                  <Link to={tab.url} style={{ textDecoration: "none" }}>
                    <Typography className={styles.footer_tabtext}>
                      {tab.name}
                    </Typography>
                  </Link>
                </div>
              );
            })}
          </div>
        </Grid>
        <Grid xs={12} sm={3}>
          <Typography className={styles.footer_upper_tabtext} variant="h6">
            ADDRESS
          </Typography>
          <Typography className={styles.footer_address}>
            Miocon Laboratories Private Limited Azad Road, North
            Lakhimpur-787001
          </Typography>
        </Grid>
        <Grid xs={12} sm={3}>
          <Typography variant="h6">Follow us on</Typography>
          <div className={styles.footer_icondiv}>
            {Icon.map((logo) => {
              return <div className={styles.footer_icon}>{logo.icon}</div>;
            })}
          </div>
        </Grid>
      </Grid>

      <hr className={styles.hrz_line} />
      <Grid container className={styles.bottomFooter}>
        <Grid md={12} xs={12} sm={12}>
          <div>
            <div style={{ paddingTop: "2px" }}>
              <Typography className={styles.bottomFooter_developed}>
                Developed By:{" "}
                <a
                  href="https://aahantechnologies.com/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  aahantechnologies
                </a>
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
