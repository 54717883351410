import React from "react";
import Layout from "../../Layout/layout";
import { Container } from "@mui/system";

import TestIcon from "../../Images/BookTest/x-rays.png";
import "../../Pages/SingleBookTest/index.css";
import { getApihandler } from "../../ApiHandler";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Grid } from "@mui/material";

export default function SingleBookTest() {
  const { testId } = useParams();
  console.log("testId", testId);

  const [laboratoryData, setLaboratoryData] = useState([]);
  // console.log("laboratoryData", laboratoryData);

  useEffect(() => {
    getLaboratory();
  }, []);

  // API's Function

  const getLaboratory = async () => {
    let result = await getApihandler(`/getLaboratoryTest/id_${testId}`);
    // console.log("res-", result);
    setLaboratoryData(result.data);
  };
  return (
    <Layout>
      <Container
        maxWidth="xl"
        className="px-5"
        style={{ minHeight: "80vh", marginTop: "10vh" }}
      >
        <div className="single-test-box-wrapper py-5 me-4 mb-4">
          {laboratoryData !== undefined &&
            laboratoryData !== null &&
            laboratoryData.map((val, ind) => {
              return (
                <Grid container className="px-5">
                  <Grid md={6} sm={4} xs={10} data-aos="slide-down">
                    <div className="aboutcard_outerdiv_text text-start">
                      <h2 className="bookText_headingtext">{val.testName}</h2>
                      <h6 className="aboutcontaint_paragraphtext">
                        <strong>Amount: &nbsp;&nbsp;&nbsp;</strong>
                        <span className="cross-price position-relative">
                          ₹{val.price}
                        </span>{" "}
                        &nbsp;&nbsp;&nbsp;₹{val.discountPrice}
                      </h6>
                      <h6
                        className={`aboutcontaint_paragraphtext ${
                          !val.description && "d-none"
                        }`}
                      >
                        <strong>Description: &nbsp;&nbsp;&nbsp;</strong>
                        <span className=" position-relative">
                          {val.description}
                        </span>{" "}
                      </h6>
                      <h6
                        className="bookText_btn"
                        style={{ marginTop: "150px" }}
                      >
                        <Link
                          to="/single-book-test "
                          style={{ textDecoration: "none" }}
                        >
                          Continue &gt;
                        </Link>
                      </h6>
                    </div>
                  </Grid>
                  <Grid md={6} sm={4} xs={10} data-aos="slide-down">
                    <div className="test-img-wrapper mb-4">
                      <img
                        // className={styles.aboutcard_img}
                        src={TestIcon}
                        alt="Our feature"
                      />
                    </div>
                  </Grid>
                </Grid>
              );
            })}
        </div>
      </Container>
    </Layout>
  );
}
