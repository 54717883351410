import React from "react";
import Banner from "../../Components/BookTestComponents/banner";
import Layout from "../../Layout/layout";
import TestList from "../../Components/BookTestComponents/TestList";

export default function BookTest() {
  return (
    <Layout>
      <Banner />
      <TestList />
    </Layout>
  );
}
