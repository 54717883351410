import { Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../Layout/layout";
import styles from "./privacy.module.css";

export default function ShipDelivery() {
  return (
    <Layout>
      <div className={styles.outer_div}>
        <div className={styles.heading}>
          <p>
            At DrDaleel, we understand the importance of receiving your
            pathology reports promptly and securely. That's why we've designed a
            streamlined and reliable ship and delivery process to ensure you
            have access to your crucial medical information when you need it
            most.
          </p>
          <h5>1. Swift Reporting:</h5>
          <p>
            Our dedicated team of healthcare professionals and lab technicians
            work diligently to process your pathology reports promptly. We
            prioritize accuracy without compromising speed, allowing you to
            access your results in a timely manner.
          </p>
          <h5>2. Secure Packaging:</h5>
          <p>
            Your privacy and confidentiality are our top priorities. All
            pathology reports are securely packaged to protect your sensitive
            medical information. We use tamper-evident seals and protective
            measures to maintain the integrity of your reports during transit.
          </p>
          <h5>3. Multiple Delivery Options:</h5>
          <p>
            DrDaleel offers multiple delivery options to cater to your specific
            needs:
            <br />
            <strong>Standard Mail:</strong> Complimentary standard mail delivery
            ensures your reports reach you efficiently. <br />{" "}
            <strong>Express Delivery:</strong> For those who require expedited
            service, we offer express delivery options to get your reports to
            you even faster.
          </p>
          <h5>4. Real-time Tracking:</h5>
          <p>
            Stay informed about the status of your report delivery with our
            real-time tracking system. You can monitor the progress of your
            shipment from the moment it leaves our facility until it arrives at
            your doorstep.
          </p>
          <h5>5. Secure Digital Access:</h5>
          <p>
            In addition to physical copies, your pathology reports are securely
            stored on our platform. You can access them anytime through our
            secure online portal or mobile app. This ensures you have a digital
            backup of your reports for easy reference and sharing with
            healthcare providers.
          </p>
          <h5>6. Dedicated Support:</h5>
          <p>
            Our customer support team is available to assist you with any
            inquiries or concerns related to your pathology reports' shipment
            and delivery. We are committed to providing a seamless and
            stress-free experience.
          </p>
          <h5>7. Eco-friendly Approach:</h5>
          <p>
            DrDaleel is committed to environmental sustainability. We strive to
            minimize our carbon footprint by using eco-friendly packaging
            materials and opting for green delivery options whenever possible.
          </p>
          <h5>8. International Shipping:</h5>
          <p>
            For our international users, we offer reliable international
            shipping services, ensuring that your pathology reports are safely
            delivered regardless of your location.
          </p>
          <p>
            At DrDaleel, our mission is to provide you with comprehensive
            healthcare services that prioritize your well-being. Our secure and
            efficient ship and delivery process for pathology reports is just
            one way we strive to enhance your healthcare experience.
          </p>
          <p>
            For any questions, concerns, or special delivery requests, please
            don't hesitate to contact our dedicated customer support team. We're
            here to ensure that you receive your pathology reports with the
            utmost convenience and peace of mind.
          </p>
          <p>
            Your health matters to us, and we're honored to be your trusted
            partner on your healthcare journey.
          </p>
        </div>
      </div>
    </Layout>
  );
}
