import { Card, Grid } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react'
import style from "./recommended.module.css"
export default function RecommendedArticle() {

  const data = [
    {
      text: "Lorem sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      imgs: "https://tse4.mm.bing.net/th?id=OIP.PdRm5oCBqUI0aows85BQ-gHaEt&pid=Api&P=0&h=180",
    },
    {
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      imgs: "https://tse1.mm.bing.net/th?id=OIP.B9cggN06Tey7br3H0ChzTwHaFU&pid=Api&P=0&h=180",
    },
    {
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      imgs: "https://tse1.mm.bing.net/th?id=OIP.Y24DclwLBdinBZ6wOCG-VwHaGP&pid=Api&P=0&h=180",
    },
  ];
  return (
    <Container sx={{paddingBottom:"3rem"}}>
      <div>
        <h2 className={style.heading}>Recommended Articles</h2>
        {data.map((val) => {
          return (
            <>
              <Card className={style.cards}>
                <Grid container className={style.outer_grid}>
                  <Grid md={6} lg={6} xs={6} >
                    {" "}
                    <div className={style.text_outer_div} style={{  }}>
                      {" "}
                      <h5 className={style.card_text}>{val.text}</h5>
                    </div>
                  </Grid>
                  <Grid md={6} lg={6} xs={6} >
                    <div>
                      <img
                        className={style.card_image}
                        src={val.imgs}
                        alt="image"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </>
          );
        })}
      </div>
    </Container>
  );

}
