import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import navlogo from "../../Images/Navbar/navlogo.png";
import styles from "./nav.module.css";
import { Link, NavLink } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";

const pages = [
  { name: "HOME", url: "/" },
  { name: "ABOUT", url: "/about" },
  { name: "HOW IT WORKS", url: "/how-it-work" },
  { name: "BLOGS", url: "/blogs" },
  { name: "CONTACT US", url: "/contact-us" },
];

function Navbar() {
  const [id, setId] = React.useState("");
  console.log("id=>", id);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const LogOut = () => {
    localStorage.removeItem("id");

    window.location.reload(false);
  };
  const Vrification = () => {
    let userid = localStorage.getItem("id");
    setId(userid);
  };
  React.useEffect(() => {
    Vrification();
  }, []);
  return (
    <AppBar position="static" sx={{ background: "white" }}>
      <Container sx={{ paddingLeft: "0", paddingRight: "0" }}>
        <Toolbar sx={{ paddingLeft: "0", paddingRight: "0" }}>
          <Box sx={{ display: { xs: "none", md: "flex" }, width: "4%", ml: 2 }}>
            <Link to="/">
              <img
                className={styles.nav_logo2}
                src={navlogo}
                alt="Drdaleel logo"
                width="100%"
                height="100%"
              />
            </Link>
          </Box>
          <AdbIcon sx={{ display: { xs: "none", md: "none" } }} />

          <Box sx={{ display: { xs: "flex", md: "none" }, width: "4%", ml: 2 }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="black"
              width="100%"
              height="100%"
            >
              <MenuIcon sx={{ width: "100%" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <NavLink to={page.url} style={{ textDecoration: "none" }}>
                    <Typography color={"black"} textAlign="center">
                      {page.name}
                    </Typography>
                  </NavLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: "none", md: "none" }, mr: 1 }} />

          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              width: "8%",
              ml: 4,
            }}
          >
            <Link to="/">
              <img
                className={styles.nav_logo2}
                src={navlogo}
                alt="Drdaleel logo"
                width="100%"
                height="100%"
              />{" "}
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, mx: 2 }}>
            {pages.map((page) => (
              <NavLink to={page.url} style={{ textDecoration: "none" }}>
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 3, mx: 3, color: "black", display: "block" }}
                >
                  {page.name}
                </Button>
              </NavLink>
            ))}
          </Box>
          <div className={styles.button_outerdiv}>
            {id == null ? (
              ""
            ) : (
              <Box sx={{ flexGrow: 0 }}>
                <Button
                  className={styles.login_buttons}
                  variant="contained"
                  onClick={() => {
                    LogOut();
                  }}
                >
                  Logout
                </Button>
              </Box>
            )}
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
