import { Container } from "@mui/system";
import React from "react";
import { Animated } from "react-animated-css";
import styles from "./about.module.css";

export default function Banner() {
  return (
    <Animated
      animationIn="fadeInUp"
      animationOut="fadeOut"
      animationInDelay="200"
      animationOutDuration=" default 1000"
      isVisible={true}
    >
      <Container maxWidth="xl" className={styles.banner_container}>
        <div className={styles.banner_container_overlay}>
          <h className={styles.banner_head}>Book Test</h>
        </div>
      </Container>
    </Animated>
  );
}
